@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;

@layer base {
  @font-face {
    font-family: "Montserrat", sans-serif;
  }
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50
}

body {
  background-color: #fff7ef;
}

.wrapper {
  @apply fixed left-0 top-0 bottom-0 z-50 w-14 bg-white flex flex-col h-screen justify-between items-center py-6 rounded-tr-xl rounded-br-xl;
}

.logo {
  @apply text-4xl text-gray-800;
}

.navListItems {
  @apply flex flex-col items-center w-full;
}

.navItem {
  @apply text-gray-400 hover:text-gray-800 text-3xl py-4 cursor-pointer;
}

.tooltip {
  @apply absolute w-auto min-w-max left-16 text-base font-medium hidden;
}

.bottomWrapper {
  @apply flex flex-col justify-between items-center;
}

.notifications {
  @apply w-10 h-10 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-xl flex items-center justify-center text-gray-800 text-lg relative mb-4;
}

.settingsLogo {
  @apply text-3xl text-gray-400;
}

.preview-body {
  background: #D1913C;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #FFD194, #D1913C);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #FFD194, #D1913C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  /*border-top: 1px solid #ddd;*/
  cursor: text;
  font-size: 16px;
  /*margin-top: 10px;*/
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.react-player {
  border-radius: 0.75rem;
  overflow: hidden;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  padding: 0 !important;
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #b4e2e4;
}

.shadow-brand {
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
}

.shadow-brand-talks {
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.05);
}

.shadow-brand-button {
  box-shadow: 0px 10px 0px -4px rgba(0, 0, 0, 0.05);
}
